<template>
    <Select
        ref="select"
        :multiple="multiple"
        :class="classes"
        :transfer-class-name="transferClassName"
        @on-change="handleChange"
        @on-open-change="handleOpenChange"
        hideNotFound
        :transfer="transfer"
    >
        <Tree
            :data="values"
            :multiple="multiple"
            @on-select-change="handleSelectNode"
            @on-check-change="handleSelectNode"
            check-strictly
            :show-checkbox="multiple && showCheckbox"
            check-directly
        ></Tree>
    </Select>
</template>
<script>
export default {
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: [String, Number, Array],
        },
        // Tree 的数据
        data: {
            type: Array,
            default: () => [],
        },
        // multiple 模式下，value 为 Array
        multiple: {
            type: Boolean,
            default: false,
        },
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        loadData: {
            type: Function,
        },
        transfer: {
            type: Boolean,
            default() {},
        },
    },
    data() {
        let value = this.value
        if (value === null) {
            if (this.multiple) value = []
            else value = ""
        }

        return {
            values: [],
            currentValue: value,
        }
    },
    watch: {
        value(val) {
            let value = val
            if (value === null) {
                if (this.multiple) value = []
                else value = ""
            }
            this.currentValue = value
            this.$refs.select.reset()
            // 解决初始化时，没有数据，无法选中的问题
            if (this.values && this.values.length > 0) this.handleUpdateTreeNodes(this.values, true)
        },
        data(val) {
            var values = JSON.parse(JSON.stringify(val || []))
            // 解决初始化时，已选项没有，测数据加入checked或selected
            this.handleUpdateTreeNodes(values, this.valueToArray.length > 0)
            this.values = values
        },
    },
    computed: {
        valueToArray() {
            return typeof this.currentValue === "object" ? this.currentValue : [this.currentValue]
        },
        isCheckboxUsable() {
            return this.multiple && this.showCheckbox
        },
    },
    methods: {
        handleSelectNode(selectedNodes, currentNode) {
            if (this.multiple) {
                if (selectedNodes.length) {
                    this.currentValue = selectedNodes.map(item => item.value)
                    this.handleUpdateSelectValue(currentNode.value, currentNode.title)
                } else {
                    this.currentValue = []
                    this.handleUpdateSelectValue("", "")
                }
            } else {
                if (selectedNodes.length) {
                    const node = selectedNodes[0]
                    this.currentValue = node.value
                    this.handleUpdateSelectValue(node.value, node.title)
                } else {
                    this.currentValue = ""
                    this.handleUpdateSelectValue("", "")
                }
            }

            this.$emit("update:value", this.currentValue)
            this.$emit("on-change", this.currentValue)
        },
        handleUpdateTreeNodes(data, isInit) {
            data.forEach(item => {
                if (this.valueToArray.indexOf(item.value) >= 0) {
                    if (this.isCheckboxUsable) {
                        item.checked = true
                    } else {
                        item.selected = true
                    }
                    if (isInit) this.handleUpdateSelectValue(item.value, item.title)
                } else {
                    if (this.isCheckboxUsable) {
                        item.checked = false
                    } else {
                        item.selected = false
                    }
                }

                if (item.children && item.children.length) {
                    this.handleUpdateTreeNodes(item.children, isInit)
                }
            })
        },
        handleUpdateSelectValue(value, label) {
            if (value === "") {
                this.$refs.select.reset()
            } else {
                this.$refs.select.onOptionClick({
                    value,
                    label,
                })
            }
        },
        handleChange(value) {
            this.currentValue = value
            this.handleUpdateTreeNodes(this.values)
            this.$emit("update:value", this.currentValue)
            this.$emit("on-change", this.currentValue)
        },
        handleOpenChange(status) {
            this.$emit("on-open-change", status)
        },
    },
    mounted() {},
}
</script>
