<template>
    <div id="portal" v-if="isPortal == true">
        <div class="portal-view" style="position: fixed; top: 0; left: 0; right: 0; height: 50px; z-index: 98; box-shadow: 0 0px 5px #00152830">
            <Menu mode="horizontal" theme="primary" style="height: 50px">
                <div class="layout-logo">
                    <img class="layout-logo-img" :src="currentSystem.branchimg ? currentSystem.branchimg : ''" @error="imgerror" v-if="currentSystem.branchimg" />
                    <span :style="'padding:0 10px;font-size:22px;font-weight:bold;color:' + (oemInfo ? oemInfo.headerFontcolor : '#eeeeee') + ';'" v-else>{{ currentSystem.branchname }}</span>
                </div>
                <div v-if="showMenuScroll" class="mscroll" style="width: 20px" title="上一页" @click="scrollMenuList(-1)">
                    <Icon type="ios-arrow-back" :color="oemInfo.headerFontcolor" size="16" />
                </div>
                <div class="layout-nav" :style="'width:' + layoutnavWidth">
                    <template v-for="(menu, index) in menuList">
                        <Submenu :key="menu.id" :name="menu.id" :class="'S' + menu.id" style="padding: 0 4px" v-if="menu.subMenus && menu.subMenus.length > 0">
                            <div @click="goSubmenu(index)" style="display: flex; flex-wrap: nowrap; padding: 0 2px 0 6px" slot="title">
                                <img :src="!menu.piclink ? '-' : menu.piclink" style="margin: 17px 5px 0 0; width: 16px; height: 16px" onerror="this.style.display='none';" />
                                <div style="white-space: nowrap">{{ menu.typename }}</div>
                            </div>

                            <template v-for="submenu in menu.subMenus">
                                <MenuGroup :key="submenu.id" :title="submenu.typename" v-if="submenu.subMenus && submenu.subMenus.length > 0">
                                    <MenuItem v-for="smenu in submenu.subMenus" :key="smenu.id" :name="smenu.id" :class="'S' + smenu.id">
                                        <div @click="goSubmenu(index, smenu.frontlink, smenu.id)" style="display: flex; height: 100%; padding: 12px">
                                            <img :src="!smenu.piclink ? '-' : smenu.piclink" style="margin: 2px 5px 0 0; width: 16px; height: 16px" onerror="this.src='./images/nopic.png';" />
                                            <div>{{ smenu.typename }}</div>
                                            <img src="/images/nopic.png" style="margin: 2px 5px 0 0; width: 10px; height: 16px" />
                                        </div>
                                    </MenuItem>
                                </MenuGroup>
                                <MenuItem :key="submenu.id" :name="submenu.id" :class="'S' + submenu.id" v-else>
                                    <div @click="goSubmenu(index, submenu.frontlink, submenu.id)" style="display: flex; height: 100%; padding: 12px">
                                        <img :src="!submenu.piclink ? '-' : submenu.piclink" style="margin: 2px 5px 0 0; width: 16px; height: 16px" onerror="this.src='./images/nopic.png';" />
                                        <div>{{ submenu.typename }}</div>
                                        <img src="/images/nopic.png" style="margin: 2px 5px 0 0; width: 10px; height: 16px" />
                                    </div>
                                </MenuItem>
                            </template>
                        </Submenu>
                        <MenuItem :key="menu.id" :name="menu.id" style="padding: 0 12px" :class="'S' + menu.id" v-else>
                            <div @click="goSubmenu(index, menu.frontlink)" style="display: flex; flex-wrap: nowrap">
                                <img :src="!menu.piclink ? '-' : menu.piclink" style="margin: 17px 5px 0 0; width: 16px; height: 16px" onerror="this.style.display='none';" />
                                <div style="white-space: nowrap">{{ menu.typename }}</div>
                            </div>
                        </MenuItem>
                    </template>
                </div>
                <div v-if="showMenuScroll" class="mscroll" style="width: 20px" title="下一页" @click="scrollMenuList(1)">
                    <Icon type="ios-arrow-forward" :color="oemInfo.headerFontcolor" size="16" />
                </div>
                <div class="layout-right" v-if="loginInfo.user">
                    <Dropdown>
                        <a href="javascript:void(0)" :style="'color:' + (oemInfo ? oemInfo.headerFontcolor : '') + ';margin:0 10px 0 5px;'">
                            <Avatar :src="loginInfo.user.picture">{{ loginInfo.user.nickname || loginInfo.user.realname || loginInfo.user.username }}</Avatar>
                            {{ loginInfo.user.realname }}
                            <Icon type="md-more" size="20" />
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem>
                                <div @click="showMDPsw">
                                    <Icon type="md-contact" />
                                    修改密码
                                </div>
                            </DropdownItem>
                            <DropdownItem v-if="sysList && sysList.length > 1">
                                <div @click="showOEMlistForm">
                                    <Icon type="md-apps" />
                                    切换应用系统...
                                </div>
                            </DropdownItem>
                            <DropdownItem>
                                <div @click="logout">
                                    <Icon type="ios-exit" />
                                    安全退出门户
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </Menu>
        </div>
        <!--侧边栏-->
        <div id="leftmenuarea" style="position: fixed; z-index: 99; top: 50px; left: 0; width: 200px; bottom: 0; overflow: auto; box-shadow: 0px 5px 5px #00152830">
            <Menu theme="dark" accordion style="width: auto; min-height: 100%; padding-top: 10px">
                <template v-for="menu in subMenus">
                    <!-- 二级菜单还有三级菜单 -->
                    <Submenu :key="menu.id" :name="menu.id" v-if="menu.subMenus && menu.subMenus.length > 0">
                        <template slot="title">
                            <div @click="syncTopMenu(menu.frontlink, menu.id)" style="display: flex; height: 100%; padding: 13px">
                                <img :src="!menu.piclink ? '-' : menu.piclink" style="margin: 3px 5px 0 0; width: 14px; height: 14px" onerror="this.src='./images/nopic.png';" />
                                <div>{{ menu.typename }}</div>
                            </div>
                        </template>
                        <template v-for="submenu in menu.subMenus">
                            <!-- 三级菜单还有四级菜单 -->
                            <MenuGroup :key="submenu.id" :title="submenu.typename" v-if="submenu.subMenus && submenu.subMenus.length > 0">
                                <MenuItem v-for="smenu in submenu.subMenus" :key="smenu.id" :name="smenu.id" :class="'S' + smenu.id">
                                    <div @click="syncTopMenu(smenu.frontlink, smenu.id)" style="display: flex; height: 100%; padding: 13px 0">
                                        <img :src="!smenu.piclink ? '-' : smenu.piclink" style="margin: 3px 5px 0 0; width: 14px; height: 14px" onerror="this.src='./images/nopic.png';" />
                                        <div>{{ smenu.typename }}1w1</div>
                                    </div>
                                </MenuItem>
                            </MenuGroup>
                            <!-- 仅三级菜单 -->
                            <MenuItem :key="submenu.id" :name="submenu.id" :class="'S' + submenu.id" v-else>
                                <div @click="syncTopMenu(submenu.frontlink, submenu.id)" style="display: flex; height: 100%; padding: 13px 0">
                                    <img :src="!submenu.piclink ? '-' : submenu.piclink" style="margin: 3px 5px 0 0; width: 14px; height: 14px" onerror="this.src='./images/nopic.png';" />
                                    <div>{{ submenu.typename }}</div>
                                </div>
                            </MenuItem>
                        </template>
                    </Submenu>
                    <!--仅二级菜单-->
                    <MenuItem :class="'S' + menu.id" :key="menu.id" :name="menu.id" v-else>
                        <div @click="syncTopMenu(menu.frontlink, menu.id)" style="display: flex; height: 100%; padding: 13px">
                            <img :src="!menu.piclink ? '-' : menu.piclink" style="margin: 3px 5px 0 0; width: 14px; height: 14px" onerror="this.src='./images/nopic.png';" />
                            <div>{{ menu.typename }}</div>
                        </div>
                    </MenuItem>
                </template>
            </Menu>
        </div>

        <div class="leftmenubtn" @click="showleftMenu" :title="menustate.title" :style="{ '--left-menu-bgcolor': $core.ColorIsLight(oemInfo.leftMenuBgcolor) ? '#515a6e' : oemInfo.leftMenuBgcolor }">
            <Icon :type="menustate.class" />
        </div>

        <div id="contentarea" style="position: fixed; top: 50px; left: 200px; right: 0; bottom: 0">
            <iframe id="mainFrame" name="mainFrame" ref="mainFrame" style="width: 100%; height: 100%; border: 0px" />
        </div>

        <div class="login_layout" style="display: ''">
            <img :src="oemInfo.loginLogo" style="height: 100px; position: absolute; top: calc((100vh - 550px) / 2 - 105px)" v-if="oemInfo.loginLogo" onerror="this.src='./images/nopic.png';" />
            <div
                :style="'position: absolute; top: calc((100vh - 550px) / 2 - 70px);left:20px;font-size:40px;font-weight:bold;color:' + (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') + ';'"
                v-else
            >
                {{ oemInfo.systemName }}
            </div>
            <div id="bgimage" class="login_image" :style="'background-image:url(' + (oemInfo.bgimages && oemInfo.bgimages.length > 0 ? oemInfo.bgimages[0] : '') + ');'"></div>
            <div class="login_div bounceIn animated" id="loginWin" style="z-index: 300">
                <img
                    id="clientloginlogo"
                    :style="'width: 100%;background:' + (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') + ';'"
                    src="/images/loginbg.png"
                    onerror="this.src='/images/nopic.png';"
                />
                <!-- <Tooltip :content="logintype.cur.msg" placement="left-end" style="position: absolute; top: 0px; right: 0px">
                    <img :src="logintype.cur.url" style="width: 100px; cursor: pointer" @click="loginType" />
                </Tooltip> -->
                <div style="width: 350px; margin: 0 55px; margin-top: 50px">
                    <div id="userlogin">
                        <Form ref="formLogin" :model="formLogin" :rules="ruleLogin">
                            <template v-if="logintype.cur.type == 'qrcode'">
                                <FormItem prop="mobile">
                                    <Input placeholder="手机号码" style="width: 350px" size="large" v-model="formLogin.mobile" @on-enter="login">
                                        <Icon type="ios-contact" slot="prefix" />
                                    </Input>
                                </FormItem>
                                <FormItem prop="password">
                                    <Input
                                        type="password"
                                        password
                                        placeholder="密码（8~32位，区分大小写）"
                                        style="width: 350px; margin-top: 10px"
                                        size="large"
                                        v-model="formLogin.password"
                                        @on-enter="login"
                                    >
                                        <Icon type="ios-unlock" slot="prefix" />
                                    </Input>
                                </FormItem>
                                <div style="display: flex; align-items: center; justify-content: flex-start; margin-top: 35px">
                                    <FormItem prop="captcha">
                                        <Input placeholder="验证码" style="width: 200px" size="large" v-model="formLogin.captcha" @on-enter="login">
                                            <Icon type="logo-angular" slot="prefix" />
                                        </Input>
                                    </FormItem>
                                    <img
                                        style="width: 128px; height: 40px; margin: -25px 0 0 20px; cursor: pointer; border: 1px solid #ddd; border-radius: 2px"
                                        title="点击刷新"
                                        @click="randCode"
                                        :src="randcodeAction"
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    :loading="loginloading"
                                    @click="login"
                                    class="loginbtn"
                                    :style="
                                        'width: 350px; margin-top: 10px;background:' +
                                        (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') +
                                        ';border:1px solid ' +
                                        (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') +
                                        ';'
                                    "
                                    size="large"
                                >
                                    <span v-if="!loginloading">登 录</span>
                                    <span v-else>验证中...</span>
                                </Button>
                            </template>
                            <template v-else>
                                <div style="margin: -30px 0; text-align: center">
                                    <img
                                        :src="captchaAppletQr.img ? 'data:image/gif;base64,' + captchaAppletQr.img : ''"
                                        style="height: 299px; min-width: 260px; background-color: #ddd"
                                        onerror="this.src='./images/nopic.png';"
                                    />
                                    <div style="width: 100%; font-size: 16px; text-align: center">请使用微信扫码登录</div>
                                </div>
                            </template>
                            <div :style="'display: flex; margin:20px 0;height: 24px; justify-content: right;font-size: 16px;color:' + (oemInfo.loginColor ? oemInfo.loginColor : '#515a6e')">
                                <div class="allow-reg" v-if="oemInfo.allowReg == 1" @click="openModal(1)">
                                    <Icon type="md-contact" size="20" />
                                    新用户注册
                                </div>
                                <div class="allow-reg" v-if="oemInfo.allowForgot == 1" @click="openModal(2)">
                                    <Icon type="md-alert" size="20" />
                                    忘记密码
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        height: 152px;
                        margin-top: 30px;
                        line-height: 1.8em;
                        color: #555;
                        font-size: 16px;
                        background-color: #f9f9f9;
                    "
                    v-html="oemInfo.copyright != null ? oemInfo.copyright.replace(/\r\n/g, '<br>').replace('|', '<br>') : ''"
                ></div>
            </div>
        </div>
        <FNCModal ref="allowModal" :title="modalType == 1 ? '新用户注册' : '忘记密码'" :fullscreen="false" width="600px" :height="modalType == 1 ? '400px' : '350px'">
            <template v-slot:contentarea>
                <Register :oemInfo="oemInfo" :modalType="modalType" @close="closeModal" />
            </template>
        </FNCModal>

        <div class="login_div bounceIn animated" id="syslistWin" style="display: none">
            <div :style="'width: 100%; height: 60px; background:' + (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') + '; border-radius: 5px 5px 0 0;'" v-if="loginInfo.user">
                <div style="font-size: 16px; color: #fff; line-height: 60px; float: left; margin-left: 10px">
                    <Avatar :src="loginInfo.user.picture" style="margin-top: -5px">{{ loginInfo.user.nickname || loginInfo.user.realname || loginInfo.user.username }}</Avatar>
                    {{ loginInfo.user.nickname || loginInfo.user.realname || loginInfo.user.username }} [{{ loginInfo.user.mobile }}] 已登录，平台为您提供以下应用：
                </div>
                <div style="text-align: right; line-height: 60px; float: right; margin-right: 10px">
                    <Icon type="ios-close" title="退出登录" color="#fff" size="50" style="margin-top: 5px; cursor: pointer" @click="logout" />
                </div>
            </div>
            <div id="systemlist" style="width: 96%; margin: 0 2%; margin-top: 20px; overflow-x: hidden; overflow-y: auto" v-if="loginInfo.login">
                <div class="sysitem fadeInLeftBig animated" v-for="(list, index1) in sysList" :key="'syslist' + index1" @click="clicksystem(list)">
                    <img
                        :src="list.branchimg ? list.branchimg : './images/nopic.png'"
                        :style="'background:' + (oemInfo.loginColor ? oemInfo.loginColor : '#17233d') + ';'"
                        onerror="this.src='./images/nopic.png';"
                    />
                    <p>{{ list.branchname }}</p>
                </div>
            </div>
        </div>
    </div>

    <router-view v-else-if="isPortal == false" :key="$route.fullPath" />
</template>
<script>
import "../utils/animate.css"
import proxy from "@/api/proxy.js"
import FNCModal from "@/components/FNCModal"
import Register from "@/components/Register"
export default {
    components: { FNCModal, Register },
    data() {
        return {
            currentSystem: {},
            frameUrl: "",
            norightUrl: "/noRight", //无权限跳转页
            loginloading: false,
            showMenuScroll: false,
            formLogin: {
                mobile: "",
                password: "",
                captcha: "",
            },
            ruleLogin: {
                mobile: [{ required: true, message: "用户不能为空。", trigger: "blur" }], //validator: validateMobile,
                password: [{ required: true, message: "密码不能为空。", trigger: "blur" }],
                captcha: [{ required: true, message: "验证码不能为空。", trigger: "blur" }],
            },
            isPortal: null, //是否门户框架
            menustate: { class: "ios-arrow-back", title: "点击隐藏侧边栏" }, //侧边栏控制
            oemInfo: {
                //   oemcode: "asd", //oem代码
                //  oemname: "平台", //OEM门户名称
                //  copyright: "广州市主导建设", //版权信息
                // loginlogo: "/images/loginlogo.png", //登录页LOGO
                bgimages: [
                    "/images/bg.jpg",
                    // "/images/bg1.jpg",
                    // "/images/bg2.jpg",
                    // "/images/bg3.jpg",
                    // "/images/bg4.jpg",
                ], //登录背景图片列表
            },
            bg_i: -1,
            loginInfo: {
                login: false, //登录成功与否：true or false
                right: false, //功能访问权限：true or false
                msg: "", //返回消息，失败原因等
                accessToken: "",
                user: {
                    address: "",
                    clientid: "",
                    jobtitle: "",
                    sex: "",
                    mobile: "",
                    picture: "",
                    realname: "",
                    school: "",
                    userids: "",
                    idisplay: 1,
                    id: "",
                    email: "",
                    username: "",
                },
            },
            userButtonRoot: [], //获取按钮权限
            sysList: [
                {
                    appCode: "", //子系统代码
                    appName: null,
                    domain: null,
                    gmtCreate: 0,
                    id: "",
                    internetIp: null,
                    intranetIp: null,
                    loginAccount: "",
                    password: "",
                    systemName: "", //子系统名称
                    tenantHostId: null,
                    tenantId: "",
                    tenantName: null,
                    sysConfigMap: {
                        //           sysHomePage: "/services", //子系统首页地址
                        //           sysLogo: "/images/portallogo_black.png", //子系统LOGO
                        //           sysImage: "/images/bg2.jpg", //子系统形象图片
                        //           isThirdSystem:false,//第三方系统标识
                        //           domain:"",//第三方系统的网址
                        //           headerBgcolor: "#2d8cf0", //门户表头背景颜色#2d8cf0
                        //           headerFontcolor: "#fff", //门户表头字体颜色#fff
                        //           headerSelectedBgcolor: "#ddefe7", //门户表头选中背景颜色#fff
                        //           headerSelectedFontcolor: "#333", //门户表头选中字体颜色#2d8cf0
                        //           leftMenuBgcolor: "#515a6e", //门户左侧菜单颜色#515a6e
                        //           leftMenuitemBgcolor: "#363e4f", //门户左侧菜单子项颜色#363e4f
                        //           leftMenuFontcolor: "rgba(255,255,255,.7)", //门户左侧菜单字体颜色 rgba(255,255,255,.7)
                        //           leftMenuSelectedBgcolor: "#2d8cf0", //门户左侧菜单选中颜色#2d8cf0
                        //           leftMenuSelecteFontcolor: "#fff", //门户左侧菜单选中字体颜色#fff
                    },
                },
            ],
            custGlobalId: "", //旧系统通过该ID直接读取用户信息。
            menuList: [],
            subMenus: [],
            layoutnavWidth: "calc(100% - 400px)",
            randcodeAction: "./images/nopic.png", //验证码IMG base64
            captchaId: "", //验证码captchaId
            captchaAppletQr: {},
            logintype: {
                //显示的图片与逻辑判断是相反的，所以qrcode即密码登录
                cur: {},
                compute: {
                    type: "compute",
                    url: "/images/lg_compute.png",
                    msg: "切换至密码登录",
                },
                qrcode: {
                    type: "qrcode",
                    url: "/images/lg_qrcode.png",
                    msg: "切换至扫码登录",
                },
            },
            monitorQRTimeout: -1,

            // 存储节流定时器
            timer: null,
            showPasswordTip: false,
            captchaTitle: "获取验证码",
            timerDown: null,
            showBtn: true,
            editLoading: false,
            preUrl: null, //权限控制中的上一级路由
            historyPreRoute: null, //写入history上一次路由
            modalType: 1,
        }
    },
    created() {
        //渲染html前
        var that = this
        if (this.checkPortal()) {
            //登录类型设置
            this.logintype.cur = this.logintype.qrcode
            if (window.localStorage.getItem("logintype")) {
                this.logintype.cur = JSON.parse(window.localStorage.getItem("logintype")) || this.logintype.qrcode
            }
            //是否主页面
            this.$(window).resize(function () {
                that.logindivresize()
                //菜单导航大小改变
                that.showMenuScrollBtn()
            })
            //登录窗定时更换背景
            that.changeBg()
            //页面显示隐藏
            document.addEventListener("visibilitychange", () => {
                if (document.hidden) {
                    //关闭图片切换
                    window.clearTimeout(this.bg_timer)
                } else {
                    //继续图片切换
                    this.changeBg()
                }
            })
        }
    },
    async mounted() {
        window.vue = this

        //渲染htm后
        if (this.isPortal) {
            //加载OEM信息
            await this.loadOEMInfo()
            //背景动画
            this.$("#bgimage").animate({ height: "550px" })

            //检查登录情况
            await this.checkLogin()

            if (this.logintype.cur.type != "qrcode" && !this.loginInfo.login) {
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            } else {
                this.randCode()
            }

            var that = this
            this.$(document).on("mouseover", ".layout-nav .ivu-menu-submenu", function () {
                that.$(this).find(".ivu-select-dropdown").css("margin-left", that.$(this).offset().left)
            })
        }
    },

    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
        menuList: function () {
            //菜单加载完成改变大小
            if (this.isPortal) setTimeout(this.menuResize, 1000)
        },
    },

    methods: {
        getProxy() {
            return proxy
        },
        fetchData() {
            var url = location.href
            if (top.vue.historyPreRoute != url) {
                top.vue.historyPreRoute = url
                // top.location.hash=(url + "").substring((url+ "").indexOf("#/") + 1)
                top.history.pushState({}, 0, url)
            }
        },
        goto(url) {
            //在编辑区打开页面

            if (url == null || url == "") return
            this.frameUrl = url
            if (self == top) {
                try {
                    this.$refs.mainFrame.contentWindow.vue.goto(this.frameUrl)
                } catch (e) {
                    if (this.frameUrl.match(/(\:\/\/)|^(\/\#)|(\.html$)|(\.html\?)|(\.htm$)|(\.htm\?)|(\.jsp$)|(\.jsp\?)|(\.action$)|(\.action\?)|(\.ac$)|(\.ac\?)/) == null) {
                        var currHash = (location.href + "").substring(0, (location.href + "").indexOf("#") + 1)
                        this.frameUrl = currHash + this.frameUrl
                    } else {
                        top.location.hash = this.frameUrl
                    }
                    this.$refs.mainFrame.contentWindow.location.href = this.frameUrl
                }
            } else {
                if (this.frameUrl.match(/(\:\/\/)|(\.html$)|(\.html\?)|(\.htm$)|(\.htm\?)|(\.jsp$)|(\.jsp\?)|(\.action$)|(\.action\?)|(\.ac$)|(\.ac\?)/g) == null) {
                    //去#
                    if (this.frameUrl.match(/\/*#/) != null) {
                        this.frameUrl = this.frameUrl.substring(this.frameUrl.indexOf("#") + 1)
                    }

                    // //是否相同页面,已用 <router-view :key="$route.fullPath"/>替代
                    // //去参
                    // var furl = this.frameUrl.indexOf("?") > 1 ? this.frameUrl.substring(0, this.frameUrl.indexOf("?")) : this.frameUrl
                    // //地址栏的地址去参去域名
                    // var lurl = (location.href + "").substring((location.href + "").lastIndexOf("#/")+1)
                    // var lurl2 = lurl.indexOf("?") > 1 ? lurl.substring(0, lurl.indexOf("?")) : lurl
                    // //相同页面需刷新同页
                    // if (furl == lurl2) {
                    //     if(this.frameUrl!=lurl){
                    //     this.$router.push(this.frameUrl)
                    //     }
                    //     this.$router.go(0)
                    // } else {
                    //     this.$router.push(this.frameUrl)
                    // }
                    this.$router.push(this.frameUrl)
                } else {
                    location.hash = this.frameUrl
                    location.href = this.frameUrl
                }
            }
        },
        checkPortal() {
            //检查是否门户框架
            if (self == top) {
                this.isPortal = true
            } else {
                this.isPortal = false
            }
            return this.isPortal
        },
        //弹出登录窗
        portalCheckRight(loginInfo) {
            if (!loginInfo.login) {
                this.loginInfo = loginInfo
                this.checkOut()
            } else if (!loginInfo.right) {
                var link = location.href
                if (link.indexOf("#/") > -1) {
                    link = link.substring(link.indexOf("#/") + 1)
                }
                if (link.indexOf(this.norightUrl) != 0) this.preUrl = link
                top.vue.goto(this.norightUrl + (loginInfo.msg ? "?msg=" + loginInfo.msg : ""))
            }
        },
        checkOut() {
            this.$Message.destroy()
            this.$Message.error(
                {
                    background: true,
                    content: this.loginInfo.msg || "请重新登录",
                    duration: 3,
                },
                false
            )

            // let layout = document.getElementsByClassName("login_layout")
            // let sysList = document.getElementById("syslistWin")
            // if (layout[0].style.display == "none") {
            //     layout[0].style.display = ""
            // }
            // if (sysList.style.display == "") {
            //     sysList.style.display = "none"
            // }
            this.$("#syslistWin").hide()
            this.$(".login_layout").show()
            this.$("#loginWin").show()

            if (this.logintype.cur.type != "qrcode") {
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            } else {
                this.randCode()
            }
        },
        async loadOEMInfo() {
            //获取OEM的配置信息接口
            await this.$get({
                url: "/gateway/oauth/loadPortalConfig",
                success: res => {
                    if (res && res.code == 200 && res.data) {
                        window.localStorage.setItem("portalCode", res.data)
                        this.oemInfo = this.$core.getPortalConfig()
                        // console.log(this.oemInfo);
                        if (this.oemInfo && this.oemInfo.id == 0) {
                            this.$Message.error({
                                background: true,
                                content: "没有读取到门户信息，请重试。",
                                duration: 3,
                            })
                            return
                        }
                        this.$("title").html(this.oemInfo.systemName)
                        this.setSystemStyle()
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "没有读取到门户信息，请重试。",
                            duration: 3,
                        })
                    }
                },
                fail: err => {
                    window.localStorage.removeItem("portalCode")
                    this.$Message.error({
                        background: true,
                        content: "读取门户信息时出错。",
                        duration: 3,
                    })
                },
            })
        },

        setSystemStyle() {
            if (!this.oemInfo) return
            this.$("#menustyle").remove()

            this.$(
                "<style id='menustyle' scoped>" +
                    ".ivu-menu-dark{background:" +
                    this.oemInfo.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-item-group-title{height:30px;line-height:30px;padding-left:8px;font-size:12px;color:#999}" +
                    ".ivu-menu-vertical .ivu-menu-item:hover,.ivu-menu-vertical .ivu-menu-submenu-title:hover{color:" +
                    this.oemInfo.leftMenuSelectedBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-group-title{color:" +
                    this.oemInfo.leftMenuFontcolor +
                    ";opacity:0.5}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title{color:" +
                    this.oemInfo.leftMenuFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):hover,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu):hover{background:" +
                    this.oemInfo.leftMenuSelectedBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item:hover,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title:hover{color:" +
                    this.oemInfo.leftMenuFontcolor +
                    ";background:" +
                    this.oemInfo.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu){color:" +
                    this.oemInfo.leftMenuSelecteFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover{color:" +
                    this.oemInfo.leftMenuFontcolor +
                    ";background:0 0!important}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover{border-right:none;color:" +
                    this.oemInfo.leftMenuSelecteFontcolor +
                    ";background:" +
                    this.oemInfo.leftMenuSelectedBgcolor +
                    "!important}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-child-item-active>.ivu-menu-submenu-title{color:" +
                    this.oemInfo.leftMenuFontcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened{background:" +
                    this.oemInfo.leftMenuitemBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title{background:" +
                    this.oemInfo.leftMenuBgcolor +
                    "}" +
                    ".ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-has-parent-submenu .ivu-menu-submenu-title{background:0 0}" +
                    ".ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu{color:rgba(255,255,255,.7)}" +
                    ".ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item-active,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu-active,.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu:hover{color:#fff}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item{margin:0;line-height:normal;padding:0px;clear:both;color:#515a6e;font-size:14px!important;white-space:nowrap;list-style:none;cursor:pointer;-webkit-transition:background .2s ease-in-out;transition:background .2s ease-in-out}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item:hover{background:#f3f3f3}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown{left:0!important;transform-origin:left top!important;position:fixed!important;max-height:calc(100vh - 100px);}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-focus{background:#f3f3f3}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-disabled{color:#c5c8ce;cursor:not-allowed}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-disabled:hover{color:#c5c8ce;background-color:#fff;cursor:not-allowed}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected,.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected:hover{color:#2d8cf0}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-divided{margin-top:5px;border-top:1px solid #e8eaec}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-divided:before{content:'';height:5px;display:block;margin:0 -16px;background-color:#fff;position:relative;top:-7px}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-enter{color:#2d8cf0;font-weight:700;float:right}" +
                    ".ivu-menu-horizontal.ivu-menu-light:after{content:'';display:block;width:100%;height:1px;background:#dcdee2;position:absolute;bottom:0;left:0}" +
                    ".ivu-menu-vertical.ivu-menu-light:after{content:'';display:block;width:1px;height:100%;background:#dcdee2;position:absolute;top:0;bottom:0;right:0;z-index:1}" +
                    ".ivu-menu-primary{background:" +
                    this.oemInfo.headerBgcolor +
                    "}" +
                    ".ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item,.ivu-menu-primary.ivu-menu-horizontal .ivu-menu-submenu{color:" +
                    this.oemInfo.headerFontcolor +
                    "}" +
                    ".ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item-selected,.ivu-menu-primary.ivu-menu-horizontal .ivu-menu-item-active>.ivu-menu-submenu-title{color:" +
                    this.oemInfo.headerSelectedFontcolor +
                    ";background:" +
                    this.oemInfo.headerSelectedBgcolor +
                    ";}" +
                    ".ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {padding: 0px;}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu-title {display: flex;flex-wrap: nowrap;align-items: center;}" +
                    ".ivu-menu-horizontal .ivu-menu-submenu-title-icon {margin-left:5px;}" +
                    ".ivu-menu-vertical .ivu-menu-submenu-title-icon {right:8px;}" +
                    ".ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item {padding-left: 28px !important;}" +
                    ".ivu-menu-horizontal {height: 50px;line-height: 50px;}" +
                    "</style>"
            ).appendTo("head")
        },
        async checkLogin() {
            if (!this.oemInfo || !this.oemInfo.client_id) return
            var userInfo = this.$core.getUserInfo()
            var tonkenInfo = this.$core.getTokenInfo()
            if (!tonkenInfo || !tonkenInfo.accessToken) return
            if (!userInfo || !userInfo.username) return
            else {
                this.loginInfo = { ...this.loginInfo, ...tonkenInfo }
                this.loginInfo.login = true
                this.loginInfo.user = userInfo
                this.loadSYSlist()
            }
        },
        changeBg() {
            //更换背景图片
            var that = this
            window.clearTimeout(this.bg_timer)
            if (that.oemInfo.bgimages.length > 1) {
                that.bg_i += 1
                that.bg_i = that.bg_i % that.oemInfo.bgimages.length
                that.$("#bgimage").fadeTo(500, 0.1, function () {
                    that.$(this).css("background-image", "url(" + that.oemInfo.bgimages[that.bg_i] + ")")
                    that.$(this).fadeTo(500, 1)
                })
            }
            this.bg_timer = setTimeout(that.changeBg, 10000)
        },
        imgerror() {
            this.currentSystem.branchimg = null
        },
        login(isQrcode, serialNumber) {
            //登录接口
            var that = this
            this.$refs.formLogin.validate(valid => {
                if (valid) {
                    this.loginloading = true
                    var logininfo = {
                        login: false, //登录成功与否：true or false
                        right: false, //功能访问权限：true or false
                        msg: "验证失败。", //返回消息，失败原因等
                        user: {},
                    }

                    let data = {}
                    // 如果是扫码登录
                    if (isQrcode == true) {
                        data = {
                            code: serialNumber,
                        }
                    } else {
                        data = {
                            username: this.formLogin.mobile,
                            password: this.formLogin.password,
                            captchaId: this.captchaId,
                            captchaCode: this.formLogin.captcha,
                            client_id: this.oemInfo.client_id,
                            client_secret: this.oemInfo.password,
                            scope: this.oemInfo.scope,
                            grant_type: this.oemInfo.grant_type,
                        }
                    }

                    // 非扫码登录
                    this.$post({ url: "/gateway/oauth/login", data: data, header: { other: "noSend" } })
                        .then(async res => {
                            if (res.code == 200) {
                                if (res.data && res.data.accessToken) {
                                    var loginData = res.data
                                    top.tokenInfo = null
                                    window.sessionStorage.setItem("userCode", this.$core.encrypt(JSON.stringify(loginData)))
                                    logininfo = {
                                        login: true, //登录成功与否：true or false
                                        right: true, //功能访问权限：true or false
                                        msg: "", //返回消息，失败原因等
                                        ...loginData,
                                    }
                                    that.loginInfo = logininfo
                                } else {
                                    that.loginInfo.msg = res.desc ? res.desc : res.msg ? res.msg : "登陆失败"
                                }
                            } else {
                                // 登陆失败之后判断是否是扫码登录，是的话就获取新的二维码
                                if (this.isQrcode == true) {
                                    this.getCaptchaAppletQr()
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                    if (res.code == "CAPTCHA_CODE_FAIL" || res.code == "USER_PASSWORD_WRONG" || res.code == "PASSWD_IS_OVERDUE") that.loginInfo.msg = res.desc
                                    else if (res.code == "CUST_LOGIN_ACCOUNT_NOT_EXIST") that.loginInfo.msg = "您的账号还未开通，请联系管理员"
                                    else that.loginInfo.msg = "登录失败，请重试"
                                } else {
                                    // 不是显示报错信息
                                    that.loginInfo.msg = res.desc ? res.desc : res.msg ? res.msg : "登陆失败"
                                    that.formLogin.captcha = ""
                                }
                            }

                            this.loginloading = false

                            if (that.loginInfo.login) {
                                //加载子系统列表
                                this.loadSYSlist()
                                return
                            } else {
                                top.tokenInfo = null
                                window.sessionStorage.removeItem("userCode")
                                //登录失败
                                this.$Message.error({
                                    background: true,
                                    content: that.loginInfo.msg,
                                    duration: 3,
                                })
                                this.randCode()
                            }
                        })
                        .catch(err => {
                            this.randCode()
                            that.loginloading = false
                            logininfo.msg = "验证出错"
                            that.loginInfo = logininfo
                            window.sessionStorage.removeItem("userCode")
                            this.$Message.error({
                                background: true,
                                content: that.loginInfo.msg,
                                duration: 3,
                            })
                        })
                }
            })
        },

        logout() {
            //退出登录
            var that = this
            this.$Modal.confirm({
                title: "退出提示",
                content: "<p>正在执行退出门户，是否继续？</p>",
                loading: true,
                onOk: () => {
                    //调用后台退出登录接口
                    this.$get({ url: "/gateway/oauth/logout" }).then(res => {
                        window.sessionStorage.removeItem("userCode")
                        window.sessionStorage.removeItem("currentSystem")
                        this.loginInfo = {
                            login: false, //登录成功与否：true or false
                            right: false, //功能访问权限：true or false
                            msg: "", //返回消息，失败原因等
                            user: {},
                        }
                        top.tokenInfo = null
                        this.$Modal.remove()
                        if (res && res.code == "200") {
                            window.location.reload()
                        } else {
                            this.$Modal.remove()
                        }
                    })
                },
            })
        },
        loadSYSlist() {
            var that = this

            //子系统列表
            if (this.loginInfo.user && this.loginInfo.user.branchUser && this.loginInfo.user.branchUser.length > 0) {
                this.sysList = this.loginInfo.user.branchUser
            }

            //构建子系统列表页
            that.$("#loginWin").hide()

            if (this.currentSystem && this.menuList && this.menuList.length > 0) {
                //重新登录则直接返回页面
                that.closeLoginForm()
                //判断当前路由是否norightUrl页
                var hashUri = location.hash
                if (hashUri && hashUri.indexOf(this.norightUrl) == 1 && this.preUrl) {
                    this.goto(this.preUrl)
                    this.preUrl = null
                }
            } else {
                if (that.sysList && that.sysList.length == 1) {
                    //单个子系统直接进入
                    that.clicksystem(that.sysList[0])
                } else {
                    that.$("#syslistWin").addClass("syslist-win")
                    that.$("#syslistWin").show(that.logindivresize)
                }
            }
        },
        async loadMENUlist() {
            //获取子系统菜单队列接口
            let back = false
            let param = { branchId: this.currentSystem.id }
            this.$Message.destroy()
            this.$Message.loading({ content: "正在加载菜单...", duration: 0 })
            await this.$get({ url: "/gateway/api/manage/appcenter/loadmenu/" + (this.currentSystem.menufatherid || "0"), data: param })
                .then(async res => {
                    this.$Message.destroy()
                    if (res.code == "200") {
                        this.menuList = res.dataList
                        back = true
                    } else {
                        this.$Message.error({ background: true, content: res.desc, duration: 3 })
                    }
                })
                .catch(e => {
                    this.$Message.destroy()
                })
            return back
        },
        goSubmenu(index, url, menuid) {
            // console.log(index, this.menuList.length);
            if (index < 0 && index >= this.menuList.length) return
            var menu = this.menuList[index]
            this.subMenus = menu.subMenus
            if (this.subMenus && this.subMenus != null && this.subMenus.length > 0) {
                this.menustate = {
                    class: "ios-arrow-forward",
                    title: "点击显示侧边栏",
                } //侧边栏控制
            } else {
                this.menustate = {
                    class: "ios-arrow-back",
                    title: "点击隐藏侧边栏",
                    hidden: true, //没有子菜单
                } //侧边栏控制
            }
            this.showleftMenu()

            var url2 = this.$core.ifNULLtoDF(url, menu.frontlink)
            if (this.$core.ifNULLtoDF(url2, null) != null) {
                this.goto(url2)
            }

            if (this.$core.ifNULLtoDF(menuid, null) != null) {
                var that = this
                var tar1 = that.$("#leftmenuarea").find(".S" + menuid)
                if (tar1 && tar1.html() != undefined && tar1.html() != null) {
                    tar1.click()
                } else {
                    setTimeout(function () {
                        var tar2 = that.$("#leftmenuarea").find(".S" + menuid)
                        tar2.click()
                    }, 1000)
                }
            }
        },
        syncTopMenu(url, menuid) {
            if (this.$core.ifNULLtoDF(menuid, null) != null) {
                this.$(".layout-nav .S" + menuid).click()
            }
            this.goto(url)
        },
        closeLoginForm() {
            //关闭登录和子系统列表窗体
            this.$("#loginWin").hide()
            this.$(".login_layout").hide()
            this.$("#syslistWin").hide()
        },
        showOEMlistForm() {
            //显示子系统列表窗体
            this.$("#loginWin").hide()
            this.$(".login_layout").show()
            this.$("#syslistWin").show(this.logindivresize())
        },
        async clicksystem(sys) {
            //获取地址栏指定的地址
            this.currentSystem = sys
            var link = this.oemInfo.sysHomePage || "/homepage"
            //优先使用地址栏的路径
            let url = window.location.href
            url = url.substring(url.indexOf("#/") + 1)
            if (url != "/") {
                //跳转到第三方http
                if (url.indexOf("/http") == 0) {
                    url = url.substring(1)
                }
                link = url
            }

            //获取子系统菜单
            var back = await this.loadMENUlist()
            if (!back) return
            if (this.menuList.length == 0) {
                this.$Message.error({ background: true, content: "该系统暂无功能菜单", duration: 3 })
                return
            }
            this.menustate = {
                class: "ios-arrow-back",
                title: "点击隐藏侧边栏",
                hidden: true, //没有子菜单
            } //侧边栏控制

            window.sessionStorage.setItem("currentSystem", JSON.stringify(this.currentSystem))
            // this.showleftMenu()
            //打开第一个侧菜单
            this.goSubmenu(0)

            this.goto(link)

            this.$("title").html(this.currentSystem.branchname)

            this.closeLoginForm()
        },
        menuResize() {
            //重置头菜单宽度大小
            //  console.log(this.$(".layout-logo").outerWidth()+this.$(".layout-right").outerWidth());
            // console.log(this.$(".layout-nav").width());
            //this.$(".layout-nav").css("width","calc(100% - "+(this.$(".layout-logo").outerWidth()+this.$(".layout-right").outerWidth()+100)+"px)");
            this.layoutnavWidth = "calc(100% - " + (this.$(".layout-logo").outerWidth() + this.$(".layout-right").outerWidth() + 60) + "px)"
            // console.log(this.layoutnavWidth+"=============="+this.$(".layout-nav").width());
            setTimeout(this.showMenuScrollBtn, 1000)
        },
        scrollMenuList(left) {
            // this.$(".layout-nav").scrollLeft(this.$(".layout-nav").scrollLeft()+(left*this.$(".layout-nav").width())-100);
            this.$(".layout-nav")
                .stop()
                .animate({ scrollLeft: this.$(".layout-nav").scrollLeft() + left * this.$(".layout-nav").width() - left * 100 }, 500)
        },
        showMenuScrollBtn() {
            // console.log(this.$(".layout-nav").prop("scrollWidth")+"============="+this.$(".layout-nav").width());
            try {
                if (this.$(".layout-nav").width() >= this.$(".layout-nav").prop("scrollWidth")) {
                    this.showMenuScroll = false
                } else {
                    this.showMenuScroll = true
                }
            } catch (e) {}
        },
        logindivresize() {
            //窗口大小改变
            var that = this
            this.$("#systemlist").css("height", this.$("#syslistWin").height() - 100)
            var sy = Math.floor(this.$("#systemlist").width() % this.$(".sysitem").width()) - 12 //12为滚动条
            var tw = Math.floor(this.$("#systemlist").width() / this.$(".sysitem").width()) * 2
            this.$("#systemlist .sysitem").css("margin", "20px " + sy / tw + "px")
        },
        showleftMenu() {
            if (this.menustate.class == "ios-arrow-back") {
                if (this.menustate.hidden == true) {
                    //没有子菜单
                    this.$("#leftmenuarea").animate({ left: "-250px" }, 200)
                    this.$(".leftmenubtn").animate({ left: "-50px" }, 200)
                } else {
                    this.$("#leftmenuarea").animate({ left: "-200px" }, 200)
                    this.$(".leftmenubtn").animate({ left: "0" }, 200)
                }
                // this.$(".leftmenubtn").css({background:this.oemInfo.leftMenuBgcolor,color:this.oemInfo.leftMenuFontcolor});
                this.$("#contentarea").animate({ left: "0" }, 200)
                this.menustate = {
                    class: "ios-arrow-forward",
                    title: "点击显示侧边栏",
                } //侧边栏控制
            } else {
                this.$("#leftmenuarea").animate({ left: "0px" }, 200)
                this.$(".leftmenubtn").animate({ left: "200px" }, 200)
                this.$("#contentarea").animate({ left: "200px" }, 200)
                this.menustate = { class: "ios-arrow-back", title: "点击隐藏侧边栏" } //侧边栏控制
            }
        },
        randCode() {
            this.randcodeAction = "./images/nopic.png"
            this.captchaId = ""
            this.$get({ url: "/gateway/oauth/captchaImage" }).then(res => {
                if (res && res.data && res.data != null) {
                    this.randcodeAction = res.data.image
                    this.captchaId = res.data.randKey
                }
            })
        },
        loginType() {
            if (this.logintype.cur.type == "qrcode") {
                this.logintype.cur = this.logintype.compute
                this.getCaptchaAppletQr()
                this.monitorCaptchaAppletQrState()
            } else {
                this.logintype.cur = this.logintype.qrcode
                this.randCode()
            }
            window.localStorage.setItem("logintype", JSON.stringify(this.logintype.cur))
        },
        getCaptchaAppletQr() {
            this.captchaAppletQr = {}
            this.$get({ url: "/gateway/oauth/getCaptchaAppletQr", data: { oemCode: this.oemInfo.oemCode } }).then(res => {
                if (res && res.code == "200" && res.data != null) {
                    //       console.log(res.data);
                    this.captchaAppletQr = res.data
                }
            })
        },
        monitorCaptchaAppletQrState() {
            clearTimeout(this.monitorQRTimeout)
            if (this.logintype.cur.type != "qrcode") {
                if (this.captchaAppletQr && this.captchaAppletQr.code != null) {
                    this.$get({ url: "/gateway/oauth/weappCodeLoginResult", data: { oemCode: this.oemInfo.oemCode, code: this.captchaAppletQr.code } })
                        .then(res => {
                            //   console.log(res);
                            if (res && res.code == "200" && res.data) {
                                // 1 等待扫码  2扫码成功  3扫码超时
                                if (res.data == "2") {
                                    this.login(true, this.captchaAppletQr.code)
                                } else if (res.data == "1") {
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                } else if (res.data == "3") {
                                    this.getCaptchaAppletQr()
                                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                    this.$Message.error({
                                        background: true,
                                        content: "小程序码已失效，已为您生成新的小程序码，请重新扫码。",
                                        duration: 3,
                                    })
                                }
                            } else if (res && res.code == "QR_AUTHENTICATION_FAILED") {
                                this.getCaptchaAppletQr()
                                this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                                this.$Message.error({
                                    background: true,
                                    content: "小程序码已失效，已为您生成新的小程序码，请重新扫码。",
                                    duration: 3,
                                })
                            } else {
                                this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                            }
                        })
                        .catch(e => {
                            this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                        })
                } else {
                    this.monitorQRTimeout = setTimeout(this.monitorCaptchaAppletQrState, 2000)
                }
            }
        },
        showMDPsw() {
            layer.open({ type: 2, content: "/nativeapp/platform/editpassword.html", title: "个人信息维护", area: ["500px", "600px"] })
        },
        openModal(type) {
            this.modalType = type
            this.$refs["allowModal"].showModal()
        },
        closeModal() {
            this.$refs["allowModal"].hideModal()
            this.randCode()
        },
    },
}
</script>
<style lang="less" scoped>
.login_layout {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    position: fixed;
    background-color: #fafafa;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.login_image {
    width: 100%;
    min-width: 1366px;
    height: 10px;
    background-repeat: repeat;
    background-color: #515a6e;
    background-size: 100% auto;
    background-position: center center;
    margin-top: calc((100vh - 550px) / 2);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.login_div {
    color: #000;
    background-color: #fff;
    border: 0pt;
    font-size: 14px;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 456px;
    height: 689px;
    position: fixed;
    right: 10%;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 5px;
    box-shadow: 2px 5px 10px #00000099;
    z-index: 200;
}

.syslist-win {
    width: 800px;
    right: calc((100vw - 800px) / 2);
    height: 80%;
}

.sysitem {
    width: 300px;
    height: 200px;
    background: #eee;
    margin: 20px;
    float: left;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #ddd;
    overflow: hidden;
    cursor: pointer;
}

.sysitem img {
    width: 300px;
    height: 150px;
    object-fit: contain;
    border-radius: 5px 5px 0 0;
    clear: both;
}

.sysitem p {
    line-height: 40px;
    font-size: 16px;
    text-align: center;
}

.layout-logo {
    float: left;
    max-height: 50px;
    min-width: 210px;
    margin: 0px;
    display: flex;
    align-items: center;
    .layout-logo-img {
        height: 50px;
        width: auto;
    }
}

.layout-nav {
    float: left;
    width: calc(100% - 400px);
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
}

.layout-right {
    float: right;
}

.leftmenubtn {
    position: fixed;
    top: 40%;
    left: 200px;
    width: 10px;
    height: 100px;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    cursor: pointer;
    z-index: 97;
    box-shadow: 3px 0px 3px #00152820;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 0.3s, background 0.3s, color 0.3s;

    border: 1px solid #c0cae2;
    border-left: 1px solid #fff;
    background: #fff;
    color: #808695;
}

.leftmenubtn:hover {
    background: var(--left-menu-bgcolor);
    color: #fff;
    border: 1px solid var(--left-menu-bgcolor);
    border-left: 1px solid #fff;
}

.mscroll {
    float: left;
    width: 20px;
    color: #eee;
    background: #00000000;
    text-align: center;
    cursor: pointer;
    z-index: 19999;
    position: relative;
}

.mscroll:hover {
    background: #00000020;
}

.capcth-div .ivu-form-item-content {
    display: flex !important;
}

.capcth-div .captcha-btn {
    border-radius: 5px;
    padding: 0 20px;
    margin-left: 20px;
    height: 35px;
    line-height: 35px;
    background: #2db7f5;
    color: #fff;
    margin-top: 2px;
    cursor: pointer;
    user-select: none;
}

.capcth-div .none {
    background: none;
    border: 1px solid #ccc;
    color: #ccc;
}

.loginbtn:hover {
    opacity: 0.7;
}

.loginbtn:active {
    opacity: 1;
}

.allow-reg {
    margin: 0 10px;
    cursor: pointer;

    &:hover {
        color: #2d8cf0;
    }
}
</style>
